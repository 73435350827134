<template>
  <div class="container con bg df">
    <div class="left">
       <div class="tip">剩余AI绘画次数: {{ this.info.draw_number }} 次（1元1条，100条起充）</div>

      <div class="option">
        <div class="title">绘画风格</div>
        <div class="list">
          <div class="sortBox" :class="idx == cur ? 'act' : ''" v-for="(i, idx) in list" :key="i.value" @click="setStyle(i, idx)">
            {{ i.label }}
          </div>
        </div>
      </div>

      <div class="option">
        <div class="title">文本描述</div>
        <div class="ipt">
          <el-input v-model="text" placeholder="请输入内容"></el-input>
        </div>
      </div>

      <div class="option">
        <div class="title">尺寸分辨率</div>
        <div class="list">
          <div class="sortBox rslt" :class="idx == type ? 'act' : ''" v-for="(i, idx) in resolution" :key="i" @click="setSize(idx)">
            {{ i }}
          </div>
        </div>
      </div>

      <div class="option">
        <div class="title">反向描述</div>
        <div class="list">
          <el-input type="textarea" resize="none" maxlength="300" show-word-limit placeholder="请输入文案话术进行合成" v-model="oppositetext"> </el-input>
        </div>
      </div>
    </div>
    <div class="right">
      <img v-show="!url" :src="baseSrc" alt="" />
      <img v-show="url" :src="url" alt="" />
     
        <div class="btns">
        <el-button type="primary" @click="draw">点击生成</el-button>
        <el-button type="primary" @click="downloadPic">下载到本地</el-button>
        <el-button type="primary" @click="save">保存到背景图</el-button>
      </div>
     
    </div>
  </div>
</template>
<script>
import { draw, preserve_draw, getUser } from "@/api/user.js";
import { download } from "@/utils/tools";

let loading;
export default {
  name: "painting",
  data() {
    return {
      index: 0,
      type: 0,
      cur: null,
      text: "",
      oppositetext: "",
      url: "",
      baseSrc: require("../../assets/loadImg.png"),
      info: {},
      list: [
        {
          label: "水墨画",
          value: 101,
        },
        {
          label: "概念艺术",
          value: 102,
        },
        {
          label: "油画1",
          value: 103,
        },
        {
          label: "油画2（梵高）",
          value: 118,
        },
        {
          label: "水彩画",
          value: 104,
        },
        {
          label: "像素画",
          value: 105,
        },
        {
          label: "厚涂风格",
          value: 106,
        },
        {
          label: "插图",
          value: 107,
        },
        {
          label: "剪纸风格",
          value: 108,
        },
        {
          label: "印象派1（莫奈）",
          value: 109,
        },
      ],
      resolution: ["1:1 (768:768)", "3:4 (768:1024)", "4:3 (1024:768)"],
    };
  },
  mounted() {
    this.info = JSON.parse(localStorage.getItem("userInfo"));
  },
  methods: {
    setSize(idx) {
      this.type = idx;
    },
    setStyle(i, idx) {
      this.styles = i.value;
      this.cur = idx;
    },
    draw() {
      loading = this.$loading({
        lock: true,
        text: "正在绘画",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      draw({
        styles: this.styles,
        text: this.text,
        type: this.type + 1,
        oppositetext: this.oppositetext,
      }).then((res) => {
        loading.close();
        if (res.code == "200") {
          this.url = res.data.url;
          getUser({ token: localStorage.getItem("token") }).then((res1) => {
            if (res1.code == "200") {
              this.info = res1.data;
            }
          });
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    downloadPic() {
      console.log(this.url, this.text);
      download(this.url, this.text);
    },
    save() {
      preserve_draw({
        url: this.url,
      }).then((res) => {
        if (res.code == "200") {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.con {
  padding: 30px 40px;

  .left {
    width: 75%;
    padding: 20px 40px 30px 10px;
    text-align: left;

    .tip {
      background: #cde1ff;
      color: #025ee9;
      padding: 17px 20px;
      font-size: 16px;
      border-radius: 10px;
      margin-bottom: 10px;
    }

    .option {
      margin-bottom: 15px;
      .title {
        color: #000000;
        font-weight: 600;
        margin-bottom: 10px;
      }
      .list {
        border-radius: 100px;
        display: flex;
        flex-wrap: wrap;

        .sortBox {
          border-radius: 4px;
          background: #ffffff;
          padding: 6px 8px;
          margin-right: 5px;
          margin-top: 5px;
          box-shadow: 0px 1px 5px #bdd3ed;
        }

        .rslt {
          width: 257px;
          text-align: center;
          margin-right: 10px;
        }
        .rslt:last-child {
          margin-right: 0px;
        }

        .act {
          border: 1px solid #58a7f8;
        }
      }

      .ipt {
        box-shadow: 0px 1px 5px #bdd3ed;
      }
    }
  }
  .right {
    padding: 20px 40px 30px;
    width: 35%;
    border-left: 1px solid #e5e5e5;

    img {
      width: 100%;
      height: 91%;
    }

    .btns {
      width: 98%;
      margin: 30px auto;
      display: flex;
      justify-content: space-between;
    }
  }
}

/deep/.el-textarea__inner {
  border: 0 !important;
  height: 276px;
  box-shadow: 0px 1px 5px #bdd3ed;
}
</style>
